.main-title {
    margin: auto;
    width: fit-content;
    padding-top: 30px;
    padding-bottom: 20px;
    color: #9d1c00;
}

.header-container {
    display: flex;
    width: fit-content;
    margin: auto;
}

.header-1 {
    margin: auto;
    width: fit-content;
    padding-bottom: 10px;
}

.display-tutorial-button {
    margin-left: 10px;
    padding: 5px;
    height: fit-content;
    width: fit-content;
    border-radius: 100px;
}

.no-recently-viewed-tickets-text {
    margin: auto;
    width: fit-content;
    padding-bottom: 50px;
}

.divider-special {
    border: 1px solid black;
    margin: 5px 50px 0px 50px;
    border-radius: 100px;
    padding-top: 2px;
}

.recently-viewed-ticket-container {
    padding-bottom: 100px;
}

.individual-recently-viewed-ticket {
    margin: 10px auto;
    width: 40vw;
    padding: 10px;
    text-align: left;
    border: black 2px solid;
    border-radius: 4px;
    background: lightgrey;
    display: block;
    box-shadow: black;
    position: relative;
}

.recently-viewed-ticket-group-header {
    font-weight: bold;
    overflow: auto;
}

.arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
}

.log-in-greeting {
    padding-top: 30px;
    margin: auto;
    width: fit-content;
    color: #9d1c00;
}

.tutorial-container {
    display: flex;
    margin: auto;
}

.step1 {
    width: fit-content;
    margin: auto;
}

.step1 img {
    width: 40vw;
    border: black solid 2px;
}

.step1 div {
    width: 40vw;
    text-align: center;
}

.step2 {
    width: fit-content;
    margin: auto;
}

.step2 img {
    width: 40vw;
    border: black solid 2px;
    margin: auto;
}

.step2 div {
    width: 40vw;
    text-align: center;
}

.step3 {
    width: fit-content;
    margin: auto;
}

.step3 img {
    width: 40vw;
    border: black solid 2px;
    margin: auto;
}

.step3 div {
    width: 40vw;
    text-align: center;
}

.step4 {
    width: fit-content;
    margin: 30px auto;
}

.step4 img {
    width: 40vw;
    border: black solid 2px;
    margin: auto;
}

.step4 div {
    width: 40vw;
    text-align: center;
}

.glance-container {
    margin: auto;
    display: flex;
}

.glance-1-img{
    width: 60vw;
    box-shadow: 10px 15px 15px 15px grey;
    border-radius: 25px;
    margin-left: 20px;
    margin-bottom: 40px;
    object-fit: contain;
    height: 100%;
}

.glance-2-img {
    width: 60vw;
    box-shadow: -10px 15px 15px 15px grey;
    border-radius: 25px;
    margin-bottom: 40px;
    object-fit: contain;
    height: 100%;
}

.glance-2-2-img {
    width: 60vw;
    display: block;
    box-shadow: 10px 15px 15px 15px grey;
    border-radius: 25px;
    margin-bottom: 40px;
    margin-left: 20px;
    object-fit: contain;
    height: 100%;
}

.glance-3-img {
    width: 90vw;
    margin: auto;
    display: block;
    box-shadow: 0px 15px 15px 10px grey;
    border-radius: 25px;
}

.glance-4-img {
    width: 90vw;
    margin: auto;
    display: block;
    box-shadow: 0px 15px 15px 10px grey;
    border-radius: 25px;
}

.glance-5-img {
    width: 90vw;
    margin: auto;
    display: block;
    box-shadow: 0px 15px 15px 10px grey;
    border-radius: 25px;
}

.glance-6-img {
    width: 28vw;
    box-shadow: -10px 15px 15px 15px grey;
    border-radius: 25px;
    margin-bottom: 40px;
    object-fit: contain;
    height: 100%;
}

.glance-1-title {
    margin: auto auto auto 50px;
    width: fit-content;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: right;
}

.glance-2-title {
    margin: auto auto auto 50px;
    width: fit-content;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: left;
}

.glance-2-2-title {
    margin: auto auto auto 50px;
    width: fit-content;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: right;
}

.glance-3-title {
    margin: auto auto auto 50px;
    width: fit-content;
    padding-top: 30px;
    padding-bottom: 40px;
    text-align: center;
    display: block;
}

.glance-4-title {
    margin: auto auto auto 50px;
    width: fit-content;
    padding-top: 30px;
    padding-bottom: 40px;
    text-align: center;
    display: block;
}

.glance-5-title {
    margin: auto auto auto 50px;
    width: fit-content;
    padding-top: 30px;
    padding-bottom: 40px;
    text-align: center;
    display: block;
}

.glance-6-title {
    margin: auto auto auto 50px;
    width: fit-content;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: left;
}

.bottom-section {
    padding-top: 40px;
    padding-bottom: 2px;
    margin: auto;
    width: fit-content;
}
.bottom-section-2 {
    padding-top: 10px;
    padding-bottom: 50px;
    margin: auto;
    width: fit-content;
}