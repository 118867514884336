.column-headers-row {
    display: inline-flex;
    text-align: center;
    font-weight: bolder;
    width: 60vw;
}

.regular-row-pending {
    display: inline-flex;
    text-align: center;
    width: 60vw;
}

.regular-row-approved {
    display: inline-flex;
    text-align: center;
    width: 60vw;
    background-color: lightgreen;
}

.regular-row-denied {
    display: inline-flex;
    text-align: center;
    width: 60vw;
    background-color: rgb(255,8,8,.5);
}

.regular-row-archived {
    display: inline-flex;
    text-align: center;
    width: 60vw;
    background-color: lightgrey;
}

.ticketID-header {
    border: solid black 2px;
    width: 10vw;
    padding: 5px 0px 5px;
}

.requestor-header {
    border: solid black 2px;
    width: 11vw;
    padding: 5px 0px 5px;
}

.subject-header {
    border: solid black 2px;
    width: 19vw;
    padding: 5px 0px 5px;
}

.date-header {
    border: solid black 2px;
    width: 10vw;
    padding: 5px 0px 5px;
}

.status-header {
    border: solid black 2px;
    width: 10vw;
    padding: 5px 0px 5px;
}

.ticketID-entry {
    border: solid black 1px;
    width: 10vw;
    padding: 10px 0px 10px;
    overflow: auto;
}

.requestor-entry {
    border: solid black 1px;
    width: 11vw;
    padding: 10px 0px 10px;
    overflow: auto;
}

.subject-entry {
    border: solid black 1px;
    width: 19vw;
    padding: 10px 0px 10px;
    overflow: auto;
}

.date-entry {
    border: solid black 1px;
    width: 10vw;
    padding: 10px 0px 10px;
}

.status-entry {
    border: solid black 1px;
    width: 10vw;
    padding: 10px 0px 10px;
}

.ticket-selected-id {
    margin: 10px auto 10px auto;
    padding-top: 10px;
    font-weight: bolder;
    width: fit-content;
}

.ticket-selected-back-button {
    margin: 10px auto 10px 10px;
    background: #9d1c00;
    font-size: small;
    color: white;
    border-radius: 100px;
    border: #9d1c00 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    left: 0;
    position: absolute;
    top: -0.5vh;
}

.ticket-selected-div {
    border: #9d1c00 solid 2px;
    height: 0px;
}

.flex-header-archived {
    display: flex;
    background: lightgrey;
}

.flex-header-approved {
    display: flex;
    background: lightgreen;
}

.flex-header-denied {
    display: flex;
    background: rgb(255,8,8,.5);
}

.flex-header-pending {
    display: flex;
    background: lightgrey;
}