.ticket-side {
    width: 79vw;
    text-align: center;
    display: inline-flex;
    margin: auto;
}

.group-info-side {
    width: 19vw;
    display: inline-flex;
    margin: auto 10px
}

.group-title {
    margin: auto;
    width: fit-content;
    padding: 10px;
    text-align: center;
    color: #9d1c00;
}

.display-block {
    display: block;
    margin: auto;
    position: relative;
}

.group-info-your-role-header {
    width: 18vw;
    text-align: center;
    border: black dashed 2px;
    padding-bottom: 20px;
}

.member-list {
    margin-top: 70px;
    display: block;
    border: dashed black 2px;
    text-align: center;
}

.ticket-title {
    margin: auto;
    padding-bottom: 10px;
}

.archived-and-new-ticket-button-container {
    position: absolute;
    right: 0;
    display: flex;
}

.new-ticket-button {
    background: #9d1c00;
    font-size: small;
    color: white;
    border-radius: 100px;
    border: #9d1c00 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    margin: 10px 10px auto;
}

.archived-tickets-button {
    background: #9d1c00;
    font-size: small;
    color: white;
    border-radius: 100px;
    border: #9d1c00 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    margin: 10px auto;
}

.back-button {
    position: absolute;
    left: 0;
    background: #9d1c00;
    font-size: small;
    color: white;
    border-radius: 100px;
    border: #9d1c00 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    margin: 10px auto;
}

.table {
    width: 60vw;
    height: 70vh;
    border: solid #9d1c00 4px;
    overflow: scroll;
}

.member-row {
    display: inline-flex;
    text-align: center;
}

.member-username-header {
    border-top: black 2px solid;
    border-right: black 2px solid;
    border-bottom: black 2px solid;
    width: 9vw;
    padding: 5px 0px 5px;
    font-weight: bolder;
}

.member-role-header {
    border-top: black 2px solid;
    border-left: black 2px solid;
    border-bottom: black 2px solid;
    width: 9vw;
    padding: 5px 0px 5px;
    font-weight: bolder;
}

.member-role-header {
    border-top: black 2px solid;
    border-left: black 2px solid;
    border-bottom: black 2px solid;
    width: 9vw;
    padding: 5px 0px 5px;
    font-weight: bolder;
}

.member-username-entry {
    width: 9vw;
    padding: 5px 0px 5px;
    border-right: black 1px solid;
}

.member-role-entry {
    width: 9vw;
    padding: 5px 0px 5px;
    border-left: black 1px solid;
}

.member-promote-container {
    display: inline-flex;
    text-align: center;
}

.member-promote-button {
    background: #9d1c00;
    font-size: small;
    color: white;
    border-radius: 100px;
    border: #9d1c00 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 5px;
    margin-left: 5px;
}

.member-kick-button {
    background: red;
    font-size: small;
    color: white;
    border-radius: 100px;
    border: #9d1c00 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.unique-code {
    border: 2px solid #9d1c00;
    margin: 5px;
    overflow-wrap: break-word;
}

.unique-code-text {
    font-size: small;
}

.leave-button {
    background: red;
    font-size: x-large;
    color: white;
    border-radius: 100px;
    border: #9d1c00 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 10px;
}

.leave-button-small {
    background: red;
    font-size: small;
    color: white;
    border-radius: 100px;
    border: #9d1c00 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 10px;
}