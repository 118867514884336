.account-action-title {
    margin: auto;
    width: fit-content;
    padding: 10px;
    text-align: center;
    color: #9d1c00;
}

.profile-container {
    width: 300px;
    margin:auto;
}

.profile-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.profile-container li div {
    margin: auto;
    width: 250px;
    padding-top: 30px;
    align-items: center;
}

.profile-container li div span {
    text-align: center;
    padding-top: 15px;
    margin: 10px auto;
    display: block;
    border: solid black 1px;
    padding-bottom: 10px;
    border-top: #9d1c00 solid 4px;
    border-right: #9d1c00 solid 3px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 5px;
}

.profile-container li div label {
    background: #9d1c00;
    color: white;
    border-radius: 100px;
    border: #9d1c00 1px;
    padding: 10px;
    display: block;
    text-align: center;

}

.profile-container li div div {
    padding: 10px;
    display: block;
    text-align: center;

}

.action-container {
    width: 300px;
    border: 1px solid black;
    border-radius: 20px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    margin:auto;
    border-top: #9d1c00 solid 4px;
    border-right: #9d1c00 solid 3px;
}

.action-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.action-container li input {
    border: 1px solid black;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    text-align: center;
    margin: 10px auto;
    border-top: #9d1c00 solid 4px;
    border-right: #9d1c00 solid 3px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 5px;
}

.action-container li button {
    background: #9d1c00;
    font-size: large;
    color: white;
    border-radius: 100px;
    border: #9d1c00 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    margin: 10px auto;
}

.action-container li button.disabled {
    background: lightgray;
    font-size: large;
    color: white;
    border-radius: 100px;
    border: #9d1c00 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    margin: 10px auto;
}

.action-container li span {
    font-size: small;
    margin-left: 10px;
}

.action-container li span.red {
    font-size: small;
    margin-left: 10px;
    color: red;
}

.action-container li span.green {
    font-size: small;
    margin-left: 10px;
    color: green;
}

.radio-buttons-container {
    display: block;
    width: fit-content;
    margin: auto;
}

.radio-button-input-div {
    width: fit-content;
    padding-left: 5px;
    display: flex;
}

.radio-button-titles {
    display: flex;
    align-items: center;
    vertical-align: center;
    padding-left: 10px;

}

.status-message {
    margin: 20px auto;
    width: fit-content;
}

.disclaimer {
    text-align: center;
    padding-bottom: 30px;
    width: 100%;
    position: absolute;
    bottom: 0%;
}

.verify-container {
    margin: 30px auto;
    width: fit-content;
}

.verify-container span {
    align-items: center;
    margin-right: 10px;
}

.verify-container input {
    margin-right: 15px;
}

.verify-container button {
    background: #9d1c00;
    font-size: large;
    color: white;
    border-radius: 100px;
    border: #9d1c00 1px;
    padding-top: 10px;
    padding-bottom: 10px;
}
