.toolbar-container {
    display: flex;
    width: 99vw;
    justify-content: flex-start;
    padding-top: 3px;
}

.toolbar-button {
    background: none;
    font-size: large;
    color: white;
    border-color: white;
    border-radius: 100px;
    font-family: arial;
}

.profile-button {
    margin-left: auto;
    background: none;
    font-size: large;
    color: white;
    border-color: white;
    border-radius: 100px;
    font-family: arial;
}

.profile-menu {
    background: white;
    position: absolute;
    top: 45px;
    right: 10px;
    width: 200px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.6s ease, visibility 0.4s, width 0.6s, top 0.6s;
}

.profile-menu.active {
    top: 55px;
    opacity: .97;
    width: 250px;
    visibility: visible;
    z-index: 100;
}

.profile-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.profile-menu li {
    border-bottom: 1px solid black;
}

.profile-menu li a {
    text-decoration: none;
    color: black;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
}

.profile-menu li span {
    text-decoration: none;
    color: grey;
    font-size: small;
    padding-top: 5px;
    padding-bottom: 5px;
    display: block;
}

.profile-menu li button {
    text-decoration: none;
    display: block;
    background: #9d1c00;
    color: white;
    border-radius: 100px;
    border: #9d1c00 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 10px auto;
}

.header-title {
    font-family: arial;
    padding-top: 1px;
    font-size: x-large;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}